import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { SpinnerModule } from 'src/app/components/spinner/spinner.module';
import { ChildcareDependentDialogComponent } from './childcare-dependent-dialog.component';

@NgModule({
  declarations: [ChildcareDependentDialogComponent],
  exports: [ChildcareDependentDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatRadioModule,
    ReactiveFormsModule,
    SpinnerModule,
  ],
})
export class ChildcareDependentDialogModule { }
