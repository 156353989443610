<div class="logged-user">
  <section
    class="text-align-center greetings"
    *ngIf="isLogged"
  >
    <p class="font-size-24 font-weight-700">Olá, {{ username }}</p>
    <button
      mat-button
      class="font-size-16 mt-8"
      color="primary"
      (click)="logout()"
    >Sair</button>
  </section>
  <section class="flex-auto company">
    <img
      class="enviei-logo"
      src="assets/enviei_colorido.svg"
      alt="enviei.me"
    />
    <div class="mt-24">
      <img
        alt="Logotipo da empresa {{ company.name }}"
        *ngIf="showImage; else noImage"
        [src]="company.logo_url"
        (error)="onLogoImageLoadError()"
      />
      <ng-template #noImage>
        <p class="
          font-size-32
          font-weight-700
          text-transform-uppercase
        ">{{ company.name }}</p>
      </ng-template>
    </div>
  </section>
  <section class="action">
    <p class="
      font-size-32
      font-weight-500
      text-align-center
      line-height-32
      mt-32
    "
    >Cadastre<br>seus documentos</p>
    <p
      *ngIf="!isLogged && company.login_type === 'saml'"
      class="font-size-14 font-weight-400 text-align-center mt-12"
    >Você será redirecionado para a tela<br>de login da sua empresa</p>
    <button
      mat-button
      class="
        login-button
        font-size-16
        font-weight-500
        border-radius-32
        py-8
        white-font
        full-width
        mt-{{ !isLogged && company.login_type === 'saml' ? '32' : '24' }}
      "
      (click)="next()"
    >Próximo</button>
    <div class="mt-32">
      <span class="mr-4 vertical-align-middle">feito por</span>
      <img
        class="closecare-logo font-size-14 font-weight-300 vertical-align-middle"
        src="assets/closecare_colorido.svg"
        alt="Closecare"
      />
    </div>
  </section>
</div>
