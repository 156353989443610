import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Dialog} from './item-picker.model';

@Component({
  selector: 'app-item-picker',
  templateUrl: './item-picker.component.html',
  styleUrls: ['./item-picker.component.scss']
})
export class ItemPickerComponent {
  selection: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Dialog,
    public dialogRef: MatDialogRef<ItemPickerComponent>
  ) {}

  public static open(dialog: MatDialog, data: Dialog): MatDialogRef<ItemPickerComponent> {
    return dialog.open(ItemPickerComponent, {
      panelClass: 'app-dialog',
      disableClose: !data.releaseClose,
      data
    });
  }
}
