import {Injectable} from '@angular/core';
import {API_GET_SICK_NOTE, ApiService} from './api.service';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {EMPTY, Observable} from 'rxjs';
import {IExtendedSickNote, ProcessingPayload, SickNoteBody} from '../models/sick-note';
import {SimpleDialogComponent} from '../components/simple-dialog/simple-dialog.component';
import {NGXLogger} from 'ngx-logger';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {differenceInHours, formatISO, setHours, setMinutes, setSeconds} from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class SickNoteService {
  public shortId: string;

  private files: File[] = [];
  private data: SickNoteBody;

  constructor(
    private api: ApiService,
    private logger: NGXLogger,
    private dialog: MatDialog
  ) {}

  public setFile(files: File[]): void {
    this.files = files;
  }

  public removeFile(): void {
    this.files.pop();
  }

  public fileExists(): boolean {
    return !!this.files.length;
  }

  setData(data: SickNoteBody): void {
    if (!this.data) {
      this.data = {};
    }
    Object.assign(this.data, data);
    this.adjustDates();
  }

  private adjustDates(): void {
    if (!!this.data.appointment_date && this.data.expiration_date) {
      let appointment = new Date(this.data.appointment_date);
      let expiration = new Date(this.data.expiration_date);
      const hours = differenceInHours(expiration, appointment);
      if (hours >= 24) {
        appointment = setHours(appointment, 0);
        appointment = setMinutes(appointment, 0);
        appointment = setSeconds(appointment, 0);
        expiration = setHours(expiration, 0);
        expiration = setMinutes(expiration, 0);
        expiration = setSeconds(expiration, 0);
      }
      this.data.appointment_date = formatISO(appointment);
      this.data.expiration_date = formatISO(expiration);
    }
  }

  /**
   * Send file and data; Show error message in case of error
   * @param api Which API to use
   */
  sendSickNote(api: string): Observable<string> {
    const formData: FormData = new FormData();

    this.buildFormData(formData, this.data);
    this.files.forEach((file: File) => formData.append('file', file, file?.name));

    // Generate correct error message and offer "Retry" option when applicable
    return this.api.http.post<{short_id: string}>(api, formData).pipe(
      map(response => {
        this.files = [];
        this.data = null;
        return response?.short_id;
      }),
      catchError((error) => {
        this.logger.debug(error);
        const duplicated = error?.status === 409;
        const errorMsg = duplicated
          ? 'Já existe um atestado igual a esse cadastrado. Você pode pedir ajuda no botão acima se precisar corrigi-lo'
          : 'Ocorreu um erro ao enviar seu atestado. Se o problema continuar, fale com o suporte pelo botão de Ajuda acima';
        const data = {
          okButton: duplicated ? 'OK' : 'Tentar novamente',
          noButton: 'Cancelar',
          title: duplicated ? 'Atestado duplicado' : 'Erro ao enviar',
          content: errorMsg
        };
        return SimpleDialogComponent.open(this.dialog, data).afterClosed()
          .pipe(
            mergeMap((tryAgain) => {
              if (!duplicated && tryAgain) {
                return this.sendSickNote(api);
              } else {
                return EMPTY;
              }
            })
          );
      }),
      tap((response) => {
        this.logger.debug('sendSickNote response', response);
      }),
    );
  }

  buildFormData(formData: FormData, data: any, parentKey?: string): void {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(Array.isArray(data) && !data.length)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
      formData.append(parentKey, value);
    }
  }

  blobToFile = (theBlob: Blob, fileName: string, lastModified?: number): File => {
    const b: any = theBlob;
    // A Blob() is almost a File() - it's just missing the two properties below
    b.lastModified = lastModified || (new Date().getTime() / 1000);
    b.name = fileName;

    // Cast to a File() type
    return theBlob as File;
  };

  getSickNote(shortId: string): Observable<IExtendedSickNote> {
    return this.api.http.get<IExtendedSickNote>(API_GET_SICK_NOTE(shortId));
  }

  getSickNoteProcessingStatus(shortId: string): Observable<ProcessingPayload> {
    return this.api.http.get<ProcessingPayload>(`${API_GET_SICK_NOTE(shortId)}/process`);
  }
}
