import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ptBR } from 'date-fns/locale';
import { MaskDateModule } from 'src/app/directives/mask-date/mask-date.module';
import { InputDateComponent } from './input-date.component';

@NgModule({
  declarations: [InputDateComponent],
  exports: [InputDateComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatDateFnsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MaskDateModule
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: ptBR,
    },
  ],
})
export class InputDateModule {}
