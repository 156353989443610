import {EMPTY, Observable, of} from 'rxjs';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {catchError, mergeMap, take} from 'rxjs/operators';
import {SimpleDialogComponent} from '../components/simple-dialog/simple-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CompanyService} from '../services/company.service';
import {Term} from '../models/term';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class TermsResolverService {
  constructor(
    public router: Router,
    private companyService: CompanyService,
    private dialog: MatDialog,
    private logger: NGXLogger,
  ) {}

  resolve(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot, route: ActivatedRoute):
    Observable<Term> {
    return this.loadData();
  }

  loadData(): Observable<Term> {
    return this.companyService.getTerms().pipe(
      take(1),
      mergeMap(info => {
        if (!info) {
          return SimpleDialogComponent.open(this.dialog, {
            okButton: 'Tentar novamente',
            noButton: 'Voltar',
            title: 'Erro ao carregar os dados',
            content: 'Verifique sua internet e tente novamente'
          }).afterClosed().pipe(mergeMap((a) => {
            if (a) {
              return this.loadData();
            }
            return EMPTY;
          }));
        }
        return of(info);
      }),
      catchError(err => {
        this.logger.error(err);
        return EMPTY;
      }),
    );
  }
}
