import {NgModule} from '@angular/core';
import {SharedModule} from '../../../../../../shared/shared.module';
import {ChatPipesModule} from '../../../../shared/pipes/pipes.module';
import {NumberModule} from './../../inputs/number/number.module';
import {TextModule} from './../../inputs/text/text.module';
import {ButtonModule} from './../../buttons/button/button.module';
import {DoctorDataComponent} from './doctor-data.component';


@NgModule({
  imports: [
    SharedModule,
    ChatPipesModule,
    NumberModule,
    TextModule,
    ButtonModule
  ],
  declarations: [
    DoctorDataComponent
  ],
  exports: [
    DoctorDataComponent
  ]
})

export class DoctorDataModule {}
