<ng-container *ngIf="company$ | async as company">
    <ng-container *ngIf="authUser$ | async as authUser; else loggedOutUser">
        <ng-container *ngIf="company.organization_id === userOrganizationId; else differentCompany">
            <app-logged-user
                [isLogged]="true"
                [username]="userName || 'boas-vindas!'"
                [company]="company"
                (onLogout)="logout()"
                (onNext)="next()"
            ></app-logged-user>
        </ng-container>
    </ng-container>

    <ng-template #loggedOutUser>
        <app-logged-user
            [company]="company"
            (onNext)="login(company.login_type, company.organization_id)"
        ></app-logged-user>
    </ng-template>

    <ng-template #differentCompany><!-- FIXME: how to make OR here? -->
        <app-logged-user
            [company]="company"
            (onNext)="login(company.login_type, company.organization_id)"
        ></app-logged-user>
    </ng-template>
</ng-container>
