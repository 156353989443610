import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {Update, Send} from '../../../../shared/models/chat.model';

@Component({
  selector: 'app-input-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})

export class TextComponent implements OnInit {
  public text: UntypedFormControl = new UntypedFormControl('', Validators.required);

  @Input() public placeholder: string;
  @Input() public reference: string;
  @Input() set value($value: string) {
    this.text.setValue($value);
  }

  @Output() private updated: EventEmitter<Update> = new EventEmitter<Update>();

  ngOnInit(): void {
    this.text.valueChanges
      .subscribe((value: string) => this.updated.emit(this.buildAnswer(value)));
  }

  private buildAnswer(value: string): Update {
    const update: Update = {
      valid: /\S/.test(value) && this.text.valid,
      answered: {
        label: value,
        send: {
          value
        }
      }
    };

    if (this.reference) {
      (update.answered.send as Send).reference = this.reference;
    }

    return update;
  }
}
