import { Component, HostBinding, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getMessaging, getToken } from 'firebase/messaging';
import * as lodash from 'lodash';
import { BehaviorSubject, Observable, combineLatest, interval, of } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, startWith, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { Answer } from 'src/app/models/file.model';
import { ChatService } from 'src/app/services/chat.service';
import { DocumentsService } from 'src/app/services/documents.service';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import { FileService } from 'src/app/services/file.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { PushNotificationsService } from 'src/app/services/push-notifications.service';
import { BaseClass } from 'src/app/shared/base-class';
import { COMPANY_TYPE, FIREBASE_VAPID_KEY } from 'src/environments/environment';
import { FileSelectedDialogComponent } from '../file-selected/file-selected-dialog.component';
import { FilePickerAnswer, InputTextAnswer } from './dynamic-form.classes';
import { ChatItemEnum, ComponentEnum } from './dynamic-form.enums';
import { DocumentStatus } from './entities/document.entity';
import {
  ChatItemType,
  ComponentType,
  DynamicFormRequest,
  DynamicFormResponse
} from './entities/dynamic-form.entity';

@UntilDestroy()
@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent extends BaseClass implements OnInit {
  companyType: string = COMPANY_TYPE;
  @HostBinding('style.--headergap')
  headergap = this.companyType === 'test' ? '94px' : '64px';

  id: string;
  organizationTypeId: number;
  organizationId: number;
  personId: string;
  form: UntypedFormGroup;
  step = 0;
  chat: ChatItemType[] = [];
  updates = new BehaviorSubject(true);
  response$: Observable<DynamicFormResponse>;
  loading = false;
  swr: ServiceWorkerRegistration;

  typingAnimationDuration = 1000;
  typingSubject = new BehaviorSubject<boolean>(false);
  typing$ = this.typingSubject
    .asObservable()
    .pipe(
      mergeMap((value) =>
        of(value).pipe(delay(value ? 0 : this.typingAnimationDuration))
      )
    );

  constructor(
    private dynamicFormService: DynamicFormService,
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fileService: FileService,
    private chatService: ChatService,
    private documentsService: DocumentsService,
    private pushNotificationsService: PushNotificationsService,
    private notificationsService: NotificationsService,
  ) {
    super();

    this.form = this.fb.group({
      items: this.fb.array([]),
    });

    this.setPipeForChatHistoryAndComponentArea();
  }

  get items() {
    return this.form.controls['items'] as UntypedFormArray;
  }

  get currentFormGroup() {
    return this.items.controls[this.step] as UntypedFormGroup;
  }

  get ComponentEnum() {
    return ComponentEnum;
  }

  ngOnInit(): void {
    const { phone, email } = this.chatService.data;
    let message = 'Olá, Iremos enviar um link para você acompanhar o status do documento nos contatos a seguir:';
    if (phone) {
      message += `<br /><strong>celular: ${phone}</strong>`;
    }
    if (email) {
      message += `<br /><strong>e-mail: ${email}</strong>`;
    }
    this.addChatItem(ChatItemEnum.ITEM_TYPE_QUESTION_HTML, message, -1);

    this.setPipeForUrlParam();
    this.setPipeForUserAnswer();

    navigator.serviceWorker.getRegistration().then((swr) => {
      this.swr = swr;
    });
  }

  async startPushNotificationsSetup(ask: boolean, component: ComponentType) {
    if (ask) {
      try {
        const permission = await Notification.requestPermission();
      if (this.swr !== undefined && permission === 'granted') {
          const messaging = getMessaging();
          const token = await getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY, serviceWorkerRegistration: this.swr });
          if (token) {
            await this.pushNotificationsService.savePushNotificationToken(true, token, this.organizationId, this.personId).toPromise();
            this.resumePushNotificationsSetup(component);
          }
        } else {
          await this.pushNotificationsService.savePushNotificationToken(false, null, this.organizationId, this.personId).toPromise();
          this.resumePushNotificationsSetup(component);
        }
      } catch (error) {
        console.log(error); // TODO: show friendly error to user
      }
    } else {
      this.resumePushNotificationsSetup(component);
    }
  }

  resumePushNotificationsSetup(component: ComponentType) {
    this.items.controls[this.step].setValue(
      {
        [component.field]: new InputTextAnswer(''),
      },
      {
        emitEvent: false,
      }
    );
    this.step += 1;
    this.nextQuestion();
  }

  setPipeForChatHistoryAndComponentArea() {
    // calls API, updates chat history and adds current component to component area
    this.response$ = this.updates.pipe(
      tap(() => this.typingSubject.next(true)),
      mergeMap(() =>
        this.dynamicFormService.getNextQuestion(
          this.id,
          this.getAnswersSoFar()
        )
      ),
      tap((response) => {
        this.createFormGroupForCurrentComponent(response.component);
        this.addQuestionsToChat(response.questions);
        this.setPipeForFileUpload(response.component);
        this.typingSubject.next(false);
      }),
      catchError((err) => {
        console.log(err); // TODO: add error message to chat
        return of(undefined);
      })
    );
  }

  setPipeForUrlParam() {
    // captures URL param and sets id
    this.activatedRoute.data
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((params) => {
        this.id = params.data.documentId;
        this.organizationTypeId = params.data.organizationTypeId;
        this.organizationId = params.data.organizationId;
        this.personId = params.data.personId;
        this.nextQuestion();
      });
  }

  setPipeForUserAnswer() {
    // receives user answer and sends it to API
    this.form.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      const control = this.items.at(this.step);
      if (control) {
        const keys = Object.keys(control.value);
        if (keys.length !== 1) {
          throw Error('invalid keys length');
        }
        const value = control.value[keys[0]];
        this.addChatItem(
          ChatItemEnum.ITEM_TYPE_ANSWER_TEXT,
          value.toChatView,
          this.step
        );
        this.step += 1;
        this.nextQuestion();
      }
    });
  }

  setPipeForFileUpload(component: ComponentType) {
    if (component?.type === ComponentEnum.COMPONENT_TYPE_FILE) {
      this.fileService.answer
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((answer: Answer) => {
          const fileDataList = answer[component.field];
          if (fileDataList) {
            const fpAnswer = new FilePickerAnswer(fileDataList);
            this.items.controls[this.step].setValue(
              {
                [component.field]: fpAnswer,
              },
              {
                emitEvent: false,
              }
            );
            this.chat.push({
              type: ChatItemEnum.ITEM_TYPE_ANSWER_IMAGE,
              files: fileDataList,
              step: this.step,
              ...(component.imageType && { imageType: component.imageType }),
            });
            this.step += 1;
            this.nextQuestion();
          }
        });
    }
  }

  skipCurrentImage(component: ComponentType) {
    const fpAnswer = new FilePickerAnswer([]);
    this.items.controls[this.step].setValue(
      {
        [component.field]: fpAnswer,
      },
      {
        emitEvent: false,
      }
    );
    this.chat.push({
      type: ChatItemEnum.ITEM_TYPE_ANSWER_TEXT,
      value: 'Não possuo esse documento.',
      step: this.step
    });
    this.step += 1;
    this.nextQuestion();
  }

  undo(): void {
    this.items.removeAt(this.step); // remove the current control (the recently created one)
    this.items.removeAt(this.step - 1); // remove the previous control (the last answered one)

    this.chat = this.chat.filter((item) => item.step < this.step - 1);

    this.step -= 1;
    this.nextQuestion();
  }

  openFilePicker(namespace: string, imageType: string) {
    FileSelectedDialogComponent.openModal(this.router, this.activatedRoute, namespace, imageType);
  }

  editFiles() {
    this.undo();
    // this.openFilePicker();
  }

  waitProcessing(shortId: string): Observable<{ processing: boolean; status: string }> {
    let runLimit = 4;
    return interval(3000)
      .pipe(
        tap(() => runLimit--),
        startWith(0),
        switchMap(() => this.documentsService.checkIfDocumentIsProcessing(shortId)
          .pipe(catchError(() => of({ processing: true, status: 'filled' })))),
        takeWhile((res) => !!res?.processing && runLimit > 0, true),
        untilDestroyed(this),
        map((res) => {
          if (!res?.processing || runLimit <= 0) {
            return {
              processing: false,
              status: res?.status
            };
          }
          return res;
        })
      );
  }

  getAnswersSoFar(): DynamicFormRequest {
    return this.items.value.map((item: UntypedFormGroup) =>
      this.mapFunction(item, 'toFormsApi')
    );
  }

  submit() {
    const finalAnswers = this.getFinalAnswers();
    this.loading = true;
    this.documentsService.createDocument(finalAnswers).pipe(
      mergeMap(({ short_id }) => combineLatest([of(short_id), this.waitProcessing(short_id)])),
      filter(([short_id, res]) => !res.processing)
    )
      .subscribe(
        ([short_id, res]) => {
          this.router.navigate(['/info', short_id], { replaceUrl: true });
        },
        error => console.log(error), // TODO: add error message to chat
        () => this.loading = false
      );
  }

  private createFormGroupForCurrentComponent(component: ComponentType) {
    // this 'if' is required because the component can be null
    // it happens when we reach the end of the form's flow
    if (component) {
      this.items.push(
        this.fb.group({
          [component.field]: [null, Validators.required],
        }),
        {
          emitEvent: false,
        }
      );
    }
  }

  private addQuestionsToChat(questions: ChatItemType[]) {
    this.chat.push(
      ...questions.map((item) => ({
        ...item,
        step: this.step,
      }))
    );
  }

  private addChatItem(type: string, value: string, step: number) {
    this.chat.push({
      type,
      value,
      step,
    });
  }

  private mapFunction(item: UntypedFormGroup, property: string) {
    const keys = Object.keys(item);
    if (keys.length !== 1) {
      throw Error('invalid keys length');
    }
    const value = item[keys[0]]?.[property];
    return {
      field: keys[0],
      value,
    };
  }

  private getFinalAnswers() {
    const request = {
      organization_type_id: this.organizationTypeId,
      person_id: this.personId,
      status: DocumentStatus.PENDING,
    };

    const value = this.items.value.reduce((acc, obj) => {
      Object.keys(obj).forEach(key => {
        const value = obj[key].toDocumentsApi;
        if (Array.isArray(value)) {
          if (!acc['files']) {
            acc['files'] = [];
          }
          acc['files'] = acc['files'].concat(
            value.map((item) => ({ ...item, id: key }))
          );
        } else {
          acc[key] = value;
        }
      });
      return acc;
    }, {});

    Object.keys(value).forEach((key) => {
      lodash.set(request, key, value[key]);
    });

    const { phone, email } = this.chatService.data;
    return {
      ...request,
      contact: {
        phone,
        email,
      }
    };
  }

  private nextQuestion(): void {
    this.updates.next(true);
  }
}
