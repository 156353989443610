import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { InputDateModule } from '../input-date/input-date.module';
import { InputTextModule } from '../input-text/input-text.module';
import { MonthPickerModule } from '../month-picker/month-picker.module';
import { InputWrapperComponent } from './input-wrapper.component';

@NgModule({
  declarations: [InputWrapperComponent],
  exports: [InputWrapperComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    InputTextModule,
    InputDateModule,
    MonthPickerModule
  ],
})
export class InputWrapperModule {}
