import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';

import {SharedModule} from '../../shared/shared.module';
import {FileRenderModule} from '../../components/file-render/file-render.module';
import {FilePreviewDialogComponent} from './file-preview-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    SharedModule,
    FileRenderModule
  ],
  declarations: [
    FilePreviewDialogComponent
  ]
})

export class FilePreviewDialogModule { }
