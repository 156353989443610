import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {formatISO, setHours, setMinutes, addHours, addDays} from 'date-fns';
import {DialogTimeComponent} from './dialog-time/dialog-time.component';
import {Answer, Answered, Period, Send, FlowType, Branch} from '../../../../shared/models/chat.model';

@Component({
  selector: 'app-button-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss']
})

export class TimeComponent implements OnInit {
  public time: UntypedFormControl = new UntypedFormControl('', Validators.required);

  @Input() public answer: Answer;
  @Input() public dependence: string;
  @Input() public flowType: FlowType;
  @Input() public allowanceHours: number;
  @Output() private answered: EventEmitter<Answered> = new EventEmitter<Answered>();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.time.valueChanges
      .subscribe((value: string) => setTimeout(() => this.setAnswer(value)));
  }

  public openDialogHour(): void {
    DialogTimeComponent.open(this.dialog, {
      answer: this.answer,
      dependence: this.dependence
    }).beforeClosed()
      .subscribe((hour: string) => {
        if (hour) {
          this.time.setValue(hour);
        }
      });
  }

  private setAnswer(label: string): void {
    this.answered.emit(this.buildAnswer(label));
  }

  private buildAnswer(label: string): Answered {
    let value: string;

    if (
      this.answer.reference.key === 'appointment_date' &&
      this.answer.reference.type === Period.hours
    ) {
      value = this.formatDateWithTime(this.dependence, label);
    }

    if (this.answer.reference.key === 'expiration_date') {
      const appointmentDate = new Date(this.dependence);

      if (this.answer.reference.type === Period.days) {
        value = formatISO(addDays(appointmentDate, Number(value)));
      }

      if (this.answer.reference.type === Period.hours) {
        const [hours, minutes] = label.split(':');
        let expirationDate = setHours(appointmentDate, Number(hours));
        expirationDate = setMinutes(expirationDate, Number(minutes));

        if (appointmentDate.getTime() > expirationDate.getTime()) {
          expirationDate = addDays(expirationDate, 1);
        }

        value = formatISO(expirationDate);
      }
    }

    let send: Send | Array<Send> = {
      value,
      reference: this.answer.reference.key
    };

    if (this.flowType === Branch.timeAllowancedVaccine) {
      send = [send].concat({
        value: formatISO(addHours(new Date(send.value as string), this.allowanceHours)),
        reference: 'expiration_date',
      });
    }

    return {
      label,
      send
    };
  }

  private formatDateWithTime(dateStr: string, time: string): string {
    let date = new Date(dateStr);
    const [hours, minutes] = time?.split(':') || ['0', '0'];
    date = setHours(date, parseInt(hours, 10));
    date = setMinutes(date, parseInt(minutes, 10));
    return formatISO(date);
  }
}
