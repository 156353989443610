import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {SharedModule} from '../../../../../shared/shared.module';
import {ChatPipesModule} from '../../../shared/pipes/pipes.module';
import {CancelComponent} from './cancel.component';


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    SharedModule,
    ChatPipesModule
  ],
  declarations: [
    CancelComponent
  ],
  exports: [
    CancelComponent
  ]
})

export class CancelModule {}
