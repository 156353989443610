import {COMMA, ENTER, SPACE, TAB} from '@angular/cdk/keycodes';
import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MatLegacyChipInputEvent as MatChipInputEvent} from '@angular/material/legacy-chips';
import IMask from 'imask';
import {Update} from '../../../../shared/models/chat.model';

@Component({
  selector: 'app-input-icd',
  templateUrl: './icd.component.html',
  styleUrls: ['./icd.component.scss']
})

export class ICDComponent implements OnInit {
  readonly separatorKeysCodes: number[] = [ENTER, SPACE, TAB];

  public icds: Array<string> = [];
  public icd: UntypedFormControl = new UntypedFormControl('', Validators.required);
  public icdMask: IMask.AnyMaskedOptions = {
    mask: [
      {mask: 'a00[0]'},
      {mask: 'a00[.0]'},
      {mask: 'a00[,0]'},
      {mask: '[1\\0]a00[0]'},
      {mask: '[1\\0]a00[.0]'},
      {mask: '[1\\0]a00[,0]'},
    ],
    signed: false,
    scale: 0,
  };

  @Input() public placeholder: string;
  @Input() public reference: string;
  @Output() private updated: EventEmitter<Update> = new EventEmitter<Update>();

  ngOnInit(): void {
    this.icd.valueChanges
      .subscribe((value: string) => this.updateEmit(value));
  }

  public add(event: MatChipInputEvent): void {
    if (event.value.length >= 3) {
      this.icd.setValue('');
      this.icds.push(event.value);
      this.updateEmit(this.icds, true, true);
    }
  }

  public remove(i: number): void {
    this.icds.splice(i, 1);
  }

  private updateEmit(value: string | Array<string>, valid?: boolean, layout?: boolean): void {
    this.updated.emit(this.buildAnswer(value, valid, layout));
  }

  private buildAnswer(value: string | Array<string>, valid: boolean, layout: boolean): Update {
    let $value: string | Array<string>;

    if (Array.isArray(value)) {
      value.forEach((v: string) => {
        $value = ($value || []).concat(this.cleanValue(v));
      });
    } else {
      $value = this.cleanValue(value);
    }

    if (!valid) {
      const pattern = /[A-Z][\d]{2,3}/g;
      const valueMatch: RegExpMatchArray = ($value as string).match(pattern);
      $value = valueMatch ? this.icds.concat(valueMatch) : this.icds;
      valid = !!(valueMatch || this.icds.length);
    }

    $value = ($value as Array<string>).join(', ');

    return {
      valid,
      layout,
      answered: {
        label: $value,
        send: {
          value: $value,
          reference: this.reference
        }
      }
    };
  }

  private cleanValue(value: string): string {
    value = value.replace('.', '').replace(',', '');

    const regex = /^(10)?([A-Z]{1}[0-9]{2,3})/ig;

    return regex.test(value) ? value.replace(regex, '$2') : '';
  }
}
