import {NgModule} from '@angular/core';
import {SickNoteComponent} from './sick-note.component';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {SharedModule} from '../../shared/shared.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatNativeDateModule} from '@angular/material/core';
import {LoadingModule} from '../../components/loading/loading.module';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MaskDateModule} from '../../directives/mask-date/mask-date.module';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    SickNoteComponent
  ],
  imports: [
    SharedModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    LoadingModule,
    MatButtonModule,
    MaskDateModule,
    ReactiveFormsModule
  ]
})
export class SickNoteModule { }
