import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SimpleDialogComponent } from '../components/simple-dialog/simple-dialog.component';
import { Document } from '../models/documents';
import { DocumentsService } from '../services/documents.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentResolverService {
  constructor(public router: Router,
    private documentsService: DocumentsService,
    private dialog: MatDialog) { }

  resolve(routeSnapshot: ActivatedRouteSnapshot): Observable<Document> {
    const shortId = routeSnapshot.paramMap.get('shortId') || routeSnapshot.queryParamMap.get('shortId');
    return this.documentsService.getDocumentByShortId(shortId).pipe(
      catchError(() => {
        SimpleDialogComponent.open(this.dialog, {
          okButton: 'OK',
          title: 'Documento não encontrado.',
          content: 'Tente novamente ou fale com a gente no botão Ajuda'
        });
        this.router.navigate(['']);
        return EMPTY;
      })
    );
  }
}
