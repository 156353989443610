import { Component, OnInit } from '@angular/core';
import {
  MatLegacyTooltip as MatTooltip,
  MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions, MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS
} from '@angular/material/legacy-tooltip';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { retry } from 'rxjs/operators';
import { SickNoteType } from '../../models/sick-note';
import { DocTypeService } from '../../services/doc-type.service';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 8000,
  touchendHideDelay: 8000,
};

@Component({
  selector: 'app-doc-type',
  templateUrl: './doc-type.component.html',
  styleUrls: ['./doc-type.component.scss'],
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}
  ],
})

export class DocTypeComponent implements OnInit {
  public loading: boolean;
  public documents: SickNoteType[] = [];

  constructor(
    public docTypeService: DocTypeService,
    private router: Router,
    private logger: NGXLogger,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.getDocTypes();
  }

  public showTooltip(event: Event, tooltip: MatTooltip): void {
    tooltip?.toggle();
    event.stopPropagation();
  }

  public next(): void {
    this.router.navigate(['/s']);
  }

  private getDocTypes(): void {
    this.docTypeService.getDocTypes()
      .pipe(retry(3))
      .subscribe(
        (documents: SickNoteType[]) => {
          this.documents = documents;
          this.loading = false;
        },
        (e) => {
          this.logger.error(e);
          // TODO show message to retry?
        }
      );
  }
}
