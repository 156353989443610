import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ChildcareDependentDialogModule } from '../childcare-dependent-dialog/childcare-dependent-dialog.module';
import { ChildcareDependentComponent } from './childcare-dependent.component';

@NgModule({
  declarations: [ChildcareDependentComponent],
  exports: [ChildcareDependentComponent],
  imports: [
    ChildcareDependentDialogModule,
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class ChildcareDependentModule {}
