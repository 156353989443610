import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

export interface SimpleDialogData {
  title?: string;
  content: string;
  disableClose?: boolean;
  okButton?: string;
  noButton?: string;
  panelClass?: string;
}

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.css']
})

export class SimpleDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SimpleDialogData,
    public dialogRef: MatDialogRef<SimpleDialogComponent>
  ) {}

  public static open(
    dialog: MatDialog,
    data: SimpleDialogData
  ): MatDialogRef<SimpleDialogComponent> {
    return dialog.open(SimpleDialogComponent, {
      panelClass: data.panelClass || 'app-dialog',
      maxWidth: 'calc(100vw - 32px)',
      disableClose: !!data.disableClose,
      data,
    });
  }
}
