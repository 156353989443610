import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DocumentCategory } from 'src/app/pages/document-categories/entities/document-types.entity';
import { AuthService } from 'src/app/services/auth.service';
import { DocTypeService } from '../../services/doc-type.service';

@UntilDestroy()
@Component({
  selector: 'app-document-types',
  templateUrl: './document-types.component.html',
  styleUrls: ['./document-types.component.scss']
})
export class DocumentTypesComponent {
  @Input()
  selectedCategory: DocumentCategory;
  dynamicFormsEnabled: boolean;
  formControl: UntypedFormControl;

  constructor(
    private location: Location,
    private router: Router,
    private docTypeService: DocTypeService,
    private authService: AuthService,
  ) {
    const state = this.location.getState() as { category: DocumentCategory };
    this.selectedCategory = state.category;
    this.formControl = new UntypedFormControl();
  }

  get disabled() {
    return this.formControl.pristine || (this.formControl.dirty && this.formControl.invalid);
  }

  next(): void {
    this.authService.user$
      .pipe(untilDestroyed(this))
      .subscribe(async (user) => {
        await user?.getIdTokenResult().then((token) => {
          const claims = token.claims;
          const selectedType = this.formControl.value;
          this.docTypeService.selected = selectedType;
          const state = {
            categoryId: this.selectedCategory.id,
            typeId: selectedType.type_id,
            organizationTypeId: selectedType.id,
            organizationId: claims?.organization_id,
            personId: claims?.person_id,
          };
          this.router.navigate(['/f'], { state });
        });
      });
  }
}
