import {Component, ChangeDetectorRef, Inject} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {BottomSheetData, AnswerType, Update, Answered, Answer, Send} from '../../../../shared/models/chat.model';
import {StatesPicker} from '../../../../../../models/pickers.model';
import {ItemPickerComponent} from '../../../../../../components/item-picker/item-picker.component';

@Component({
  selector: 'app-answer-doctor-data',
  templateUrl: './doctor-data.component.html',
  styleUrls: ['./../corrections.scss']
})

export class DoctorDataComponent {
  public answerType: any = AnswerType;
  public fields: {[key: string]: Update} = {};
  public state: {
    id: string;
    label: string;
  };

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private bottomSheetRef: MatBottomSheetRef<DoctorDataComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: BottomSheetData
  ) {}

  public get fieldsInvalid(): boolean {
    const fields: Array<Answer> = this.data?.bottomSheet?.fields;

    for (const i in fields) {
      if (
        !this.fields[fields[i].type]?.valid &&
        fields[i].required
      ) {
        return true;
      }
    }
  }

  public updated(type: string, event): void {
    this.fields[type] = event;
  }

  public openItemPicker(): void {
    ItemPickerComponent.open(this.matDialog, {
      header: 'Selecione o estado',
      select: 'SELECIONAR',
      itens: this.data.states
    }).afterClosed()
      .subscribe((stateId: string) => this.setDataState(stateId));
  }

  public sendAnswer(): void {
    this.bottomSheetRef.dismiss(this.buildAnswer());
  }

  private setDataState(stateId: string): void {
    this.state = {
      id: stateId,
      label: StatesPicker[stateId] || ''
    };

    this.changeDetectorRef.detectChanges();
  }


  private buildAnswer(): Answered {
    const answered: Answered = {send: []};

    if (this.fields.number) {
      answered.label = `${this.fields.number.answered.label}<br>${this.state.label}`;
      answered.adjusted = true;
    }

    this.data.bottomSheet.fields.forEach((field: Answer) => {
      if ((this.fields[field.type]?.answered.send as Send)?.value) {
        (answered.send as Array<Send>).push({
          value: this.buildSend(field.type),
          reference: field.reference.key
        });
      }
    });

    return answered;
  }

  private buildSend(fieldType: string): string {
    if (fieldType === AnswerType.picker) {
      return this.state.id;
    }

    return (this.fields[fieldType]?.answered.send as Send)?.value as string;
  }
}
