import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ChildcareInstitutionDialogModule } from '../childcare-institution-dialog/childcare-institution-dialog.module';
import { ChildcareInstitutionComponent } from './childcare-institution.component';

@NgModule({
  declarations: [ChildcareInstitutionComponent],
  exports: [ChildcareInstitutionComponent],
  imports: [
    ChildcareInstitutionDialogModule,
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class ChildcareInstitutionModule {}
