import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ptBR } from 'date-fns/locale';
import { LoadingModule } from 'src/app/components/loading/loading.module';
import { ButtonLinkModule } from './components/button-link/button-link.module';
import { ChatHistoryModule } from './components/chat-history/chat-history.module';
import { ChildcareDependentModule } from './components/childcare/childcare-dependent/childcare-dependent.module';
import { ChildcareInstitutionModule } from './components/childcare/childcare-institution/childcare-institution.module';
import { InputWrapperModule } from './components/input-wrapper/input-wrapper.module';
import { TwoButtonsModule } from './components/two-buttons/two-buttons.module';
import { DynamincFormRoutingModule } from './dynamic-form-routing.module';
import { DynamicFormComponent } from './dynamic-form.component';
import { DynamicFormsResolver } from './dynamic-forms.resolver';

@NgModule({
  declarations: [DynamicFormComponent],
  imports: [
    ButtonLinkModule,
    ChatHistoryModule,
    ChildcareDependentModule,
    ChildcareInstitutionModule,
    CommonModule,
    DynamincFormRoutingModule,
    InputWrapperModule,
    LoadingModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatToolbarModule,
    ReactiveFormsModule,
    TwoButtonsModule,
  ],
  providers: [
    DynamicFormsResolver,
    {
      provide: MAT_DATE_LOCALE,
      useValue: ptBR,
    },
  ],
})
export class DynamicFormModule {}
