import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {TextComponent} from './text.component';


@NgModule({
  imports: [
    ReactiveFormsModule,
    MatInputModule
  ],
  declarations: [
    TextComponent
  ],
  exports: [
    TextComponent
  ]
})

export class TextModule {}
