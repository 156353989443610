import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {SharedModule} from '../../../../../../shared/shared.module';
import {PickerComponent} from './picker.component';


@NgModule({
  imports: [
    ReactiveFormsModule,
    MatButtonModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    SharedModule
  ],
  declarations: [
    PickerComponent
  ],
  exports: [
    PickerComponent
  ]
})

export class PickerModule {}
