import {NgModule} from '@angular/core';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';

import {PdfViewerModule} from 'ng2-pdf-viewer';

import {SharedModule} from '../../shared/shared.module';
import {SimpleDialogModule} from '../../components/simple-dialog/simple-dialog.module';
import {FileRenderModule} from '../../components/file-render/file-render.module';
import {FilePreviewDialogModule} from '../file-preview/file-preview-dialog.module';
import {FileSelectedDialogComponent} from './file-selected-dialog.component';

@NgModule({
  imports: [
    MatBottomSheetModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatToolbarModule,
    PdfViewerModule,
    SharedModule,
    SimpleDialogModule,
    FileRenderModule,
    FilePreviewDialogModule
  ],
  declarations: [
    FileSelectedDialogComponent
  ]
})

export class FileSelectedDialogModule {}
