import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { DocumentCategoriesComponent } from './document-categories.component';
import { DocumentTypesResolver } from './document-types.resolver.';



@NgModule({
  declarations: [
    DocumentCategoriesComponent
  ],
  exports: [
    DocumentCategoriesComponent
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    PipesModule
  ],
  providers: [DocumentTypesResolver]
})
export class DocumentCategoriesModule { }
