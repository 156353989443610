import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  NgControl,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Subject } from 'rxjs';
import { MonthPickerAnswer } from '../../dynamic-form.classes';

@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MonthPickerComponent,
    },
  ],
})
export class MonthPickerComponent
  implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<Date> {
  @Input()
  label: string;

  @Input()
  placeholder: string;

  startDate: Date = new Date();

  @Input()
  set value(value: Date) {
    this.dateFormControl.setValue(value);
    this.monthFormControl.setValue(
      value ?
        capitalizeFirstLetter(format(value, 'MMMM, yyyy', { locale: ptBR }))
         : null
      );
    this.stateChanges.next();
  }
  get value() {
    return this.dateFormControl.value;
  }

  stateChanges = new Subject<void>();
  dateFormControl: UntypedFormControl;
  monthFormControl: UntypedFormControl;

  id: string;
  focused: boolean;
  empty: boolean;
  shouldLabelFloat: boolean;
  required: boolean;
  disabled: boolean;
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;

  onChange = (value: any) => { };
  onTouched = () => { };

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.dateFormControl = new UntypedFormControl('');
    this.monthFormControl = new UntypedFormControl('');
  }

  ngOnInit(): void {
    this.dateFormControl.valueChanges.subscribe((value) => {
      if (value) {
        this.onChange(new MonthPickerAnswer(value));
      } else {
        this.onChange(null);
      }
    });
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  openDatePicker(dp) {
    dp.open();
  }

  closeDatePicker(eventData: any, dp?: any) {
    this.value = eventData;
    dp.close();
  }

  writeValue(value: Date): void {
    this.value = value;
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(isDisabled: boolean): void { }
  setDescribedByIds(ids: string[]): void { }
  onContainerClick(event: MouseEvent): void { }
}

const capitalizeFirstLetter = (name: string): string => name.charAt(0).toUpperCase() + name.slice(1);
