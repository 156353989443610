import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { FileRenderModule } from 'src/app/components/file-render/file-render.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ChatHistoryComponent } from './chat-history.component';

@NgModule({
  declarations: [ChatHistoryComponent],
  exports: [ChatHistoryComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, FileRenderModule, PipesModule],
})
export class ChatHistoryModule {}
