import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {Update, Send} from '../../../../shared/models/chat.model';
/* eslint-disable-next-line */
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  selector: 'app-input-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})

export class EmailComponent implements OnInit {
  public email: UntypedFormControl = new UntypedFormControl('', [Validators.email, Validators.pattern(EMAIL_REGEX)]);

  @Input() set value($value: string) {
    this.email.setValue($value);

    if ($value) {
      this.updated.emit(this.buildAnswer($value));
    }
  }

  @Input() public placeholder: string;
  @Input() public reference: string;
  @Output() private updated: EventEmitter<Update> = new EventEmitter<Update>();

  ngOnInit(): void {
    this.email.valueChanges
      .subscribe((value: string) => this.updated.emit(this.buildAnswer(value)));
  }

  private buildAnswer(value: string): Update {
    const update: Update = {
      valid: this.email.valid,
      answered: {
        label: value,
        send: {
          value
        }
      }
    };

    if (this.reference) {
      (update.answered.send as Send).reference = this.reference;
    }

    return update;
  }
}
