import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ItemPickerComponent} from './item-picker.component';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';



@NgModule({
  declarations: [ItemPickerComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatListModule,
    MatRadioModule
  ],
  exports: [ItemPickerComponent]
})
export class ItemPickerModule {}
