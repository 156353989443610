import {Component, ViewChild, ElementRef} from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import imageCompression from 'browser-image-compression';

import {blobToArrayBuffer} from '../../shared/utils';
import {FileType, FileData, FileDialog} from '../../models/file.model';
import {SimpleDialogComponent} from '../../components/simple-dialog/simple-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-files-bottom-sheet',
  templateUrl: './files-bottom-sheet.component.html',
  styleUrls: ['./files-bottom-sheet.component.scss']
})

export class FileBottomSheetComponent {
  private type: FileType;

  @ViewChild('nativeCamera') nativeCamera: ElementRef;
  @ViewChild('filePicker') filePicker: ElementRef;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<FileBottomSheetComponent>,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar
  ) {}

  async fileTaken(files: FileList): Promise<void> {
    if (!files.length) {
      return;
    }

    let fileDialog: FileDialog;

    const file = files.item(0);
    const pattern = /(\.jpg|\.jpeg|\.png|\.pdf|\.gif|\.tiff|\.webp|\.jfif|\.heic|\.heif)$/;
    // const hasExtension = this.fileExtensionRegex.test(file?.name);

    if (!pattern.exec(file?.name?.toLowerCase())) {
      SimpleDialogComponent
        .open(this.dialog, {
          title: 'Arquivo inválido',
          content: 'São aceitos arquivos apenas nos formatos: JPG, PNG, PDF, GIF, TIFF, WEBP, JFIF, HEIC, HEIF',
          okButton: 'OK',
          disableClose: true
        })
        .beforeClosed()
        .pipe(untilDestroyed(this))
        .subscribe();

      return;
    }

    try {
      fileDialog = this.buildFileDialog(await this.setFile(file));
    } catch (e) {
      this.matSnackBar.open(
        'Erro ao carregar arquivo. Confira se é um formato aceito (png, jpg, pdf) e tente novamente.',
        null,
        {duration: 2000}
      );
    }

    this.bottomSheetRef.dismiss(fileDialog);
  }

  selectPicture(): void {
    this.type = FileType.picture;
    this.nativeCamera.nativeElement.value = '';
    this.nativeCamera.nativeElement.click();
  }

  selectFile(): void {
    this.type = FileType.file;
    this.filePicker.nativeElement.value = '';
    this.filePicker.nativeElement.click();
  }

  private async setFile(file: File): Promise<File> {
    const buffer = await blobToArrayBuffer(file);
    const options = {
      // maxSizeMB: 3,
      maxWidthOrHeight: 4000,
      initialQuality: 0.8,
      useWebWorker: true
    };

    let value: File = new File([buffer], file.name, {type: file.type, lastModified: file.lastModified});

    if (file?.type?.includes('image/')) {
      const blob = await imageCompression(value, options);
      value = this.blobToFile(blob, value.name, value.lastModified);
    }

    return value;
  }

  private blobToFile(theBlob: Blob, fileName: string, lastModified?: number): File {
    const blob: any = theBlob;
    // A Blob() is almost a File() - it's just missing the two properties below
    blob.lastModified = lastModified || (new Date().getTime() / 1000);
    blob.name = fileName;

    // Cast to a File() type
    return theBlob as File;
  }

  private buildFileDialog(file: File): FileDialog {
    const fileDialog: FileDialog = {
      fileData: {
        file,
        contentType: file.type
      }
    };

    if (this.type === FileType.picture) {
      fileDialog.okButton = 'Foto ficou boa';
      fileDialog.noButton = 'Refazer foto';
    } else {
      fileDialog.okButton = 'Continuar';
      fileDialog.noButton = 'Escolher outro';
    }

    return fileDialog;
  }

  private fileSelected(): void {
    this.bottomSheetRef.dismiss('picture');
  }
}
