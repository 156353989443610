import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Company } from 'src/app/services/company.service';

@Component({
  selector: 'app-logged-user',
  templateUrl: './logged-user.component.html',
  styleUrls: ['./logged-user.component.scss']
})
export class LoggedUserComponent {
  showImage: boolean;

  @Input() isLogged: boolean;
  @Input() username: string;
  @Input() company: Company;

  @Output() onLogout: EventEmitter<string> = new EventEmitter();
  @Output() onNext: EventEmitter<string> = new EventEmitter();

  constructor() {
    this.showImage = true;
  }

  onLogoImageLoadError() {
    this.showImage = false;
  }

  logout() {
    this.onLogout.emit();
  }

  next() {
    this.onNext.emit();
  }
}
