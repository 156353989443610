import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {SharedModule} from '../../../../../shared/shared.module';
import {ChatPipesModule} from '../../../shared/pipes/pipes.module';
import {TextModule} from './text/text.module';
import {CodeModule} from './code/code.module';
import {NumberModule} from './number/number.module';
import {EmailModule} from './email/email.module';
import {PhoneModule} from './phone/phone.module';
import {ICDModule} from './icd/icd.module';
import {InputsComponent} from './inputs.component';


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    SharedModule,
    ChatPipesModule,
    TextModule,
    CodeModule,
    NumberModule,
    EmailModule,
    PhoneModule,
    ICDModule
  ],
  declarations: [
    InputsComponent
  ],
  exports: [
    InputsComponent
  ]
})

export class InputsModule {}
